import { checkIsBusinessUser } from "selectors/user";
import {
  checkSelectedBusinessHasBusinessUsers,
  checkSelectedBusinessRpaMode,
  getCurrentQuickBooksRealmId,
  getSelectedBusinessBookCloseDate,
  getSelectedBusinessData,
  getSelectedBusinessId,
  getSelectedBusinessIntegrationServiceData,
  getTransactionsInProgressStats
} from "selectors/businesses";
import { checkTransactionsFetching, getTransactionsData } from "selectors/transactions";
import { getActiveOrganization } from "selectors/organizations";
import { getContactsData } from "selectors/contacts";
import { getTextsData } from "selectors/texts";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Async from "utils/Async";
import BusinessesActions from "actions/BusinessesActions";
import CommentsActions from "actions/CommentsActions";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import IntegrationServices from "const/IntegrationServices";
import OrganizationsActions from "actions/OrganizationsActions";
import RoboticActions from "actions/RoboticActions";
import Transactions from "utils/Transactions";
import TransactionsActions from "actions/TransactionsActions";
import TransactionsStatuses from "nlib/pages/TransactionsPage/TransactionsStatuses";
import UiActions from "actions/UiActions";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import moment from "moment";
import useEnvVars from "hooks/useEnvVars";
import useShowCommonModal from "./useShowCommonModal";
import useTransactionUtils from "./useTransactionUtils";

const {
  STATUSES: { TO_RECONCILE, NEED_REACTION, TO_REPORT, TO_REVIEW, EXPORTED, IN_PROGRESS },
  COMMENT_TARGET_TYPES,
  COMMENT_TYPES
} = DataConstants;

const { QUICK_BOOKS } = IntegrationServices;

const TRANSACTION_FIELDS_TO_EDIT = [
  "id",
  "item",
  "tags",
  "class",
  "reason",
  "address",
  "project",
  "taxRate",
  "category",
  "location",
  "vendorId"
];

const INITIAL_STATE = { transactionsState: [], transactionsData: [] };

const checkAskClientAllowed = (status, quickBooksBusiness) => {
  return [TO_RECONCILE, NEED_REACTION, TO_REVIEW].includes(status) || (status === EXPORTED && quickBooksBusiness);
};

const getTransactionChanges = (prevState, prevData, nextData) => {
  const changes = TRANSACTION_FIELDS_TO_EDIT.map((fieldName) => {
    const prevStateValue = prevState[fieldName];

    const prevDataValue = prevData[fieldName];

    const nextDataValue = nextData[fieldName];

    switch (fieldName) {
      case "id":
      case "reason":
        return null;
      case "item":
      case "class":
      case "location":
      case "project":
      case "taxRate":
        return (prevDataValue?.name === nextDataValue?.name) || (!prevDataValue?.name && prevStateValue?.name)
          ? false
          : [fieldName, nextDataValue];
      case "tags":
        return (prevDataValue?.join() === nextDataValue?.join()) || (!prevDataValue?.join() && prevStateValue?.join())
          ? false
          : [fieldName, nextDataValue];
      case "address":
      case "vendorId":
        return (prevData.vendorId === nextData.vendorId) || (!prevData.vendorId && prevState.vendorId)
          ? false
          : [fieldName, nextDataValue];
      default:
        return (prevDataValue === nextDataValue) || (!prevDataValue && prevStateValue)
          ? false
          : [fieldName, nextDataValue];
    }
  }).filter(Boolean);

  return changes.length ? Object.fromEntries(changes) : null;
};

const updateTransactionsState = ({
  prevState,
  data,
  prevData,
  contactsData,
  integrationService,
  lastGptAiFineTuneStatus
}) => {
  return data
    ? data.map((nextTransactionData) => {
      const prevTransactionState = Utils.arrayFindById(prevState, nextTransactionData.id);

      const prevTransactionData = Utils.arrayFindById(prevData, nextTransactionData.id, {});

      if (!prevTransactionState) {
        return Transactions.getTransactionState(nextTransactionData, lastGptAiFineTuneStatus);
      }

      const changes = getTransactionChanges(prevTransactionState, prevTransactionData, nextTransactionData);

      return changes ? { ...prevTransactionState, ...changes } : prevTransactionState;
    }).map((transactionState) => Transactions.updateContactData(transactionState, contactsData, integrationService))
    : prevState;
};

const useTransactions = ({ defaultStatus } = {}) => {
  const dispatch = useDispatch();

  const [envVars] = useEnvVars();

  const { uiTexts, messages } = useSelector(getTextsData);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const selectedBusinessBookCloseDate = useSelector(getSelectedBusinessBookCloseDate);

  const businessUser = useSelector(checkIsBusinessUser);

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const selectedBusinessIntegrationServiceData = useSelector(getSelectedBusinessIntegrationServiceData);

  const {
    byRoboticAiStatus: {
      awaitingReconciliation: {
        count: awaitingReconciliationCount = 0
      } = {}
    } = {}
  } = useSelector(getTransactionsInProgressStats) || {};

  const {
    id: businessId,
    settings: { advancedDocumentsWorkflow } = {},
    extraData: {
      integrationService,
      lastGptAiFineTuneStatus,
      transactionsLastSyncedAt,
      integrationServiceConnected,
      lastManualRpaWorkflowStartedAt
    } = {}
  } = useSelector(getSelectedBusinessData);

  const quickBooksService = integrationService === QUICK_BOOKS.value;

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const selectedBusinessHasBusinessUsers = useSelector(checkSelectedBusinessHasBusinessUsers);

  const { businessOrganization } = useSelector(getActiveOrganization);

  const { checkIsReadyToReview, checkIsReadyToApprove } = useTransactionUtils();

  const showCommonModal = useShowCommonModal();

  const [selectedTransactions, setSelectedTransactions] = useState([]);

  const [fetching, setFetching] = useState(false);

  const firstCallRef = useRef(true);

  const { text } = envVars;

  const searchByMongoId = !!text && Utils.checkIsMongoDbId(text.replace("activityId:", ""));

  let {
    pageSize = Constants.TABLE_PAGE_SIZE,
    page = 1,
    sortBy,
    sortOrder,
    month,
    status = (!searchByMongoId && businessUser)
      ? NEED_REACTION
      : defaultStatus,
    fromDate,
    toDate,
    accountId,
    type
  } = envVars;

  if (month) {
    const momentDate = moment(month);

    if (momentDate.isValid()) {
      fromDate = momentDate.startOf("month").format(Constants.DATETIME_FORMATS.API_DATE);
      toDate = momentDate.endOf("month").format(Constants.DATETIME_FORMATS.API_DATE);
    }
  }

  const transactionsData = useSelector(getTransactionsData);

  const transactionsFetching = useSelector(checkTransactionsFetching);

  const contactsData = useSelector(getContactsData);

  const [{ transactionsState }, setState] = useState(INITIAL_STATE);

  const setTransactionsState = useCallback((newState) => {
    setState((prev) => {
      const updatedState = typeof newState === "function"
        ? newState(prev.transactionsState) : newState;

      return { ...prev, transactionsState: updatedState };
    });
  }, []);

  const hasFilters = !![text, fromDate, toDate, accountId, type].find(Boolean);

  const fetchData = useCallback(async(clearList = false, backgroundUpdate = false) => {
    const result = await dispatch(TransactionsActions.fetchTransactionsList(
      searchByMongoId ? undefined : status,
      { text, fromDate, toDate, accountId, type },
      sortBy ? { id: sortBy, desc: sortOrder === "desc" } : null,
      (page - 1) * pageSize,
      +pageSize,
      clearList,
      backgroundUpdate
    ));

    return result;
  }, [
    dispatch,
    page,
    text,
    type,
    status,
    sortBy,
    toDate,
    fromDate,
    pageSize,
    sortOrder,
    accountId,
    searchByMongoId
  ]);

  const refetchTransactions = useCallback(async(transactionsToReInit, silently) => {
    const result = await fetchData(false, silently);

    setState((prevState) => {
      return {
        transactionsData: result,
        transactionsState: updateTransactionsState({
          data: result,
          prevState: prevState.transactionsState,
          prevData: prevState.transactionsData,
          contactsData,
          integrationService,
          lastGptAiFineTuneStatus
        })
      };
    });
  }, [integrationService, lastGptAiFineTuneStatus, contactsData, fetchData]);

  const selectedTransactionsData = useMemo(() => {
    return transactionsData.filter(({ id }) => selectedTransactions.includes(id));
  }, [selectedTransactions, transactionsData]);

  const closedBookDateTransactionIds = useMemo(() => {
    if (!selectedBusinessBookCloseDate || !transactionsData) return [];

    return transactionsData.filter(({ timestamp }) => {
      return moment(timestamp).isSameOrBefore(moment(selectedBusinessBookCloseDate))
        && (quickBooksBusiness || status !== EXPORTED);
    }).map(({ id }) => id);
  }, [selectedBusinessBookCloseDate, quickBooksBusiness, status, transactionsData]);

  const editableTransactionsIds = useMemo(() => {
    if (envVars.status === IN_PROGRESS) return [];

    return transactionsData.filter(({ status: transactionStatus, aiProcessing }) => {
      if (businessUser) return transactionStatus === NEED_REACTION;

      return (!aiProcessing && transactionStatus !== TO_REPORT && (quickBooksBusiness || transactionStatus !== EXPORTED));
    }).map(({ id }) => id);
  }, [envVars.status, transactionsData, businessUser, quickBooksBusiness]);

  const transactionsReadyToProcess = useMemo(() => {
    if (!rpaMode || businessUser) return [];

    return transactionsState
      .filter((item) => {
        const transactionData = Utils.arrayFindById(transactionsData, item.id);

        return editableTransactionsIds.includes(item.id)
          && transactionData?.status === NEED_REACTION && !item.manualMode && !!item.reason?.trim();
      });
  }, [rpaMode, businessUser, transactionsState, transactionsData, editableTransactionsIds]);

  const transactionsReadyToReview = useMemo(() => {
    return transactionsState
      .filter((item) => {
        const transactionData = Utils.arrayFindById(transactionsData, item.id);

        return transactionData && checkIsReadyToReview(item, transactionData);
      });
  }, [transactionsData, transactionsState, checkIsReadyToReview]);

  const transactionsReadyToApprove = useMemo(() => {
    if (!integrationServiceConnected) return [];

    return transactionsState
      .filter((transactionState) => {
        const transactionData = Utils.arrayFindById(transactionsData, transactionState.id);

        if (!transactionData) return null;

        return checkIsReadyToApprove(transactionState, transactionData);
      });
  }, [integrationServiceConnected, transactionsData, transactionsState, checkIsReadyToApprove]);

  const selectedNeedReactionIds = useMemo(() => {
    if (businessUser) return [];

    return selectedTransactionsData.filter((transaction) => {
      const currentStatus = envVars.status || Utils.arrayFindById(transactionsData, transaction.id, {}).status;

      return checkAskClientAllowed(currentStatus, quickBooksBusiness);
    }).map(({ id }) => id);
  }, [businessUser, quickBooksBusiness, selectedTransactionsData, envVars.status, transactionsData]);

  const [popupMode, transactionsForPopup] = useMemo(() => {
    if (selectedNeedReactionIds.length && (!businessOrganization || selectedBusinessHasBusinessUsers)) {
      return [null, []];
    }

    if (envVars.status === IN_PROGRESS) {
      if (!transactionsLastSyncedAt || !awaitingReconciliationCount) return [];

      return [IN_PROGRESS, new Array(awaitingReconciliationCount)];
    }
    if (transactionsReadyToApprove.length) {
      return [TO_REPORT, transactionsReadyToApprove.filter(({ id }) => !closedBookDateTransactionIds.includes(id))];
    }
    if (transactionsReadyToProcess.length) {
      return [TO_REVIEW, transactionsReadyToProcess.filter(({ id }) => !closedBookDateTransactionIds.includes(id))];
    }
    if (transactionsReadyToReview.length) {
      return [TO_REVIEW, transactionsReadyToReview.filter(({ id }) => !closedBookDateTransactionIds.includes(id))];
    }

    return [null, []];
  }, [
    envVars.status,
    awaitingReconciliationCount,
    transactionsReadyToApprove,
    transactionsReadyToProcess,
    transactionsReadyToReview,
    transactionsLastSyncedAt,
    selectedNeedReactionIds.length,
    businessOrganization,
    selectedBusinessHasBusinessUsers,
    closedBookDateTransactionIds
  ]);

  const startRpa = useCallback(async() => {
    const [
      manualRpaRunDescriptionA,
      manualRpaRunDescriptionB,
      manualRpaRunDescriptionC
    ] = messages.manualRpaRunDescription;

    const manualRpaStartTimeLeft = lastManualRpaWorkflowStartedAt
      ? Math.max(
        moment.utc(lastManualRpaWorkflowStartedAt)
          .add(Constants.MANUAL_RPA_START_COOLDOWN, "milliseconds").diff(moment.utc()),
        0
      )
      : 0;

    const modalResult = await showCommonModal({
      text: [
        manualRpaRunDescriptionA,
        manualRpaStartTimeLeft
          ? Utils.replaceTextVars(
            manualRpaRunDescriptionB,
            { cooldownTime: moment.duration(manualRpaStartTimeLeft).humanize(true) }
          )
          : manualRpaRunDescriptionC
      ].join("\n\n"),
      headerText: manualRpaStartTimeLeft ? uiTexts.notification : uiTexts.confirm,
      confirm: !manualRpaStartTimeLeft,
      okButtonText: manualRpaStartTimeLeft ? uiTexts.ok : uiTexts.continue
    });

    if (modalResult && !manualRpaStartTimeLeft) {
      await dispatch(RoboticActions.startRpaWorkflow(
        quickBooksService ? Constants.RPA_WORKFLOWS.MATCH_TRANSACTIONS : Constants.RPA_WORKFLOWS.RECONCILE_TRANSACTONS
      ));
      await dispatch(BusinessesActions.fetchBusiness(businessId, true));
    }
  }, [
    businessId,
    dispatch,
    quickBooksService,
    lastManualRpaWorkflowStartedAt,
    showCommonModal,
    uiTexts,
    messages
  ]);

  const askClient = useCallback(async(userEmail, userPhone = "", comment) => {
    const savedEmailLsKey = `${Constants.LS_KEYS.LAST_ASKED_BUSINESS_EMAIL}_${selectedBusinessId}`;

    const successful = await dispatch(OrganizationsActions.askBusinessUser(
      selectedNeedReactionIds, userEmail, userPhone, comment
    ));

    if (successful) Utils.storageValue(savedEmailLsKey, `${userEmail}:${moment().unix()}`);
    dispatch(OrganizationsActions.fetchUsersList());
    refetchTransactions();
  }, [dispatch, refetchTransactions, selectedBusinessId, selectedNeedReactionIds]);

  const askNewClient = useCallback(async(comment) => {
    const result = await dispatch(UiActions.showAskClientWindow({
      transactionsCount: selectedNeedReactionIds.length
    }));

    if (result) {
      const { userEmail, userPhone, invite } = result;

      if (invite) {
        await dispatch(OrganizationsActions.inviteUser({
          email: userEmail,
          role: UserRoles.BUSINESS_MANAGER.roleId,
          businessIds: [selectedBusinessId],
          emailNotifications: true
        }));
      }
      await askClient(userEmail, userPhone, comment);
    }
  }, [askClient, dispatch, selectedBusinessId, selectedNeedReactionIds]);

  const onSelectedChange = useCallback((transactionId, value) => {
    setSelectedTransactions((prev) => value ? [...prev, transactionId] : prev.filter((el) => el !== transactionId));
  }, []);

  const onAskClientPopupClose = useCallback((result) => {
    setSelectedTransactions([]);
    if (result) {
      const { userEmail, userPhone, comment } = result;

      if (userEmail) askClient(userEmail, userPhone, comment);
      else askNewClient(comment);
    }
  }, [askClient, askNewClient, setSelectedTransactions]);

  const onBulkActionsEdit = useCallback(async(data) => {
    const {
      address,
      vendorId,
      category,
      class: classValue,
      location,
      project,
      taxRate,
      directCategorySelection,
      tags,
      reason,
      status: innerStatus
    } = data;

    if (selectedTransactionsData.some(({ id }) => closedBookDateTransactionIds.includes(id))) {
      const closedBookTimeText = moment.utc(selectedBusinessBookCloseDate).format(Constants.DATETIME_FORMATS.DATE_TEXT_EXT);

      showCommonModal({
        text: Utils.replaceTextVars(messages.transactionsClosedBookWarning, {
          date: closedBookTimeText,
          service: selectedBusinessIntegrationServiceData.label
        }),
        headerText: uiTexts.warning
      });

      return;
    }

    setFetching(true);

    setSelectedTransactions([]);

    const allowedToEditTransactionsData = selectedTransactionsData.filter((item) => {
      return !closedBookDateTransactionIds.includes(item.id)
        && item.status !== TO_REPORT && (item.status !== EXPORTED || quickBooksBusiness);
    });

    if (allowedToEditTransactionsData.length) {
      if (innerStatus) {
        const ids = allowedToEditTransactionsData.filter((item) => {
          const { canBeAssigned } = TransactionsStatuses.getStatusData([innerStatus]);

          return canBeAssigned && item.status !== innerStatus;
        }).map(({ id }) => id);

        setState((prevState) => {
          return {
            ...prevState,
            transactionsState: prevState.transactionsState.map((item) => {
              return ids.includes(item.id) ? { ...item, status: innerStatus } : item;
            })
          };
        });

        await dispatch(TransactionsActions.bulkTransactionsUpdate({ ids, data: { status: innerStatus } }));
      } else {
        const fullUpdate = allowedToEditTransactionsData.map(({ id, documentId }) => {
          const updates = [
            innerStatus && { status: innerStatus },
            vendorId && { address, vendorId },
            reason && { reason },
            ...((!advancedDocumentsWorkflow || !documentId) ? [
              category && { category, directCategorySelection, tags },
              classValue && { class: classValue },
              location && { location },
              project && { project },
              taxRate && { taxRate }
            ] : [])
          ].filter(Boolean);

          return updates.length && updates.reduce((aggregator, update) => ({ ...aggregator, ...update }), { id });
        }).filter(Boolean);

        if (fullUpdate.length) {
          const result = await dispatch(TransactionsActions.bulkTransactionsUpdate(null, fullUpdate));

          if (result) {
            if (reason) {
              await Async.runInSequence(selectedTransactions.map((id) => async() => {
                await dispatch(CommentsActions.addNewComment(
                  COMMENT_TYPES.COMMENT, `${uiTexts.reason}: ${reason}`, [], true, id, COMMENT_TARGET_TYPES.TRANSACTIONS
                ));
              }));
            }
          }
        }
      }
      refetchTransactions();
    }
    setFetching(false);
  }, [
    uiTexts,
    messages,
    quickBooksBusiness,
    selectedTransactionsData,
    advancedDocumentsWorkflow,
    selectedTransactions,
    closedBookDateTransactionIds,
    selectedBusinessIntegrationServiceData,
    selectedBusinessBookCloseDate,
    showCommonModal,
    refetchTransactions,
    dispatch
  ]);

  const onBulkActionsCancel = useCallback(() => {
    setSelectedTransactions([]);
  }, []);

  const onTransactionsPopupSubmit = useCallback(async() => {
    if (envVars.status === IN_PROGRESS) {
      startRpa();

      return;
    }

    const payload = transactionsForPopup
      .filter(({ id }) => !closedBookDateTransactionIds.includes(id))
      .map((transaction) => {
        return Utils.getProps(transaction, TRANSACTION_FIELDS_TO_EDIT);
      });

    await dispatch(TransactionsActions.bulkTransactionsUpdate(null, payload));

    refetchTransactions();

    await Async.runInSequence(payload.map(({ id, reason }) => async() => {
      if (reason) {
        await dispatch(CommentsActions.addNewComment(
          COMMENT_TYPES.COMMENT,
          `${uiTexts.reason}: ${reason}`,
          [],
          true,
          id,
          COMMENT_TARGET_TYPES.TRANSACTIONS
        ));
      }
    }));
  }, [envVars.status, transactionsForPopup, closedBookDateTransactionIds, uiTexts, dispatch, refetchTransactions, startRpa]);

  useEffect(() => {
    setState((prevState) => {
      const newState = updateTransactionsState({
        data: transactionsData,
        prevState: prevState.transactionsState,
        prevData: prevState.transactionsData,
        contactsData,
        integrationService,
        lastGptAiFineTuneStatus
      });

      return { ...prevState, transactionsState: newState };
    });
  }, [integrationService, lastGptAiFineTuneStatus, contactsData, businessUser, transactionsData]);

  useLayoutEffect(() => {
    fetchData(firstCallRef.current);

    firstCallRef.current = false;

    return Utils.setInterval(
      () => fetchData(false, true), Constants.DATA_LIST_UPDATE_INTERVAL
    );
  }, [fetchData]);

  return {
    hasFilters,
    transactionsFetching,
    transactionsData,
    transactionsState,
    popupMode,
    fetching,
    transactionsForPopup,
    selectedTransactions,
    selectedTransactionsData,
    editableTransactionsIds,
    transactionsReadyToProcess,
    transactionsReadyToReview,
    transactionsReadyToApprove,
    selectedNeedReactionIds,
    closedBookDateTransactionIds,
    setTransactionsState,
    refetchTransactions,
    setFetching,
    setSelectedTransactions,
    onSelectedChange,
    onAskClientPopupClose,
    onBulkActionsEdit,
    onBulkActionsCancel,
    onTransactionsPopupSubmit
  };
};

export default useTransactions;

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Badge, Button, DropDown, Input, Popup } from "nlib/ui";
import { DropDownCaret, DropDownContent, DropDownMenuItem, DropDownToggle } from "nlib/ui/DropDown";
import { getAllUsersData } from "selectors/organizations";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Card from "nlib/common/Card";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import DropDownMenuDivider from "nlib/ui/DropDown/lib/DropDownMenuDivider";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const { STATUSES: { NEED_REACTION } } = DataConstants;

const AskClientPopup = ({ disabled, className, statusFilter, transactionIds, onClose }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const usersData = useSelector(getAllUsersData);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const [shown, setShown] = useState(false);

  const [lastAskedUserEmail, setLastAskedUserEmail] = useState("");

  const [comment, setComment] = useState("");

  const needReactionStatusFilter = statusFilter === NEED_REACTION;

  const savedEmailLsKey = `${Constants.LS_KEYS.LAST_ASKED_BUSINESS_EMAIL}_${selectedBusinessId}`;

  const allBusinessUsersData = useMemo(() => {
    return usersData.filter(({ role, businessIds }) => {
      return UserRoles.checkIsBusiness(role) && (!businessIds.length || businessIds.includes(selectedBusinessId));
    });
  }, [usersData, selectedBusinessId]);

  const businessUsersData = useMemo(() => {
    return allBusinessUsersData.filter(({ guestUser }) => !guestUser);
  }, [allBusinessUsersData]);

  const guestUsersData = useMemo(() => {
    return allBusinessUsersData.filter(({ guestUser }) => guestUser);
  }, [allBusinessUsersData]);

  const suggestedBusinessUser = useMemo(() => {
    const lastAskedUser = lastAskedUserEmail
      && allBusinessUsersData.find(({ email }) => email.toLowerCase() === lastAskedUserEmail.toLowerCase());

    return lastAskedUser || allBusinessUsersData[0] || {};
  }, [lastAskedUserEmail, allBusinessUsersData]);

  const suggestedBusinessUserName = suggestedBusinessUser.fullName || suggestedBusinessUser.email;

  const transactionsHash = transactionIds.join();

  const transactionsCount = transactionIds.length;

  const handleCloseClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleAskNewClientButtonClick = useCallback(() => {
    onClose({ comment });
  }, [comment, onClose]);

  const handleAskClientButtonClick = useCallback(() => {
    if (suggestedBusinessUser.id) {
      const userData = usersData.find(({ id }) => id === suggestedBusinessUser.id);

      onClose({ userEmail: userData.email, userPhone: userData.phone, comment });
    } else {
      onClose();
    }
  }, [suggestedBusinessUser.id, usersData, onClose, comment]);

  const handleMenuItemClick = useCallback((value) => {
    if (value) {
      const userData = usersData.find(({ id }) => id === value);

      onClose({ userEmail: userData.email, userPhone: userData.phone, comment });
    } else {
      onClose({ comment });
    }
  }, [usersData, onClose, comment]);

  const handleCommentInputChange = useCallback((value) => {
    setComment(value);
  }, []);

  useEffect(() => {
    setShown(!!transactionsHash);
  }, [transactionsHash]);

  useEffect(() => {
    let savedEmail = Utils.storageValue(savedEmailLsKey);

    let emailAddedAt;

    if (savedEmail) {
      try {
        [savedEmail, emailAddedAt] = savedEmail.split(":");
        emailAddedAt = moment.unix(emailAddedAt);
        if (moment().subtract(Constants.LAST_ASKED_EMAIL_EXPIRATION_DURATION, "milliseconds").isAfter(emailAddedAt)) {
          savedEmail = "";
        }
      } catch (error) {
        savedEmail = "";
      }
    }
    if (savedEmail) setLastAskedUserEmail(savedEmail);
    else setLastAskedUserEmail("");
  }, [statusFilter, allBusinessUsersData, selectedBusinessId, savedEmailLsKey]);

  return (
    <Popup
      shown={shown}
      className={classNames(Css.askClientPopup, className)}>
      <Card className={Css.card}>
        <Input
          className={Css.comment}
          disabled={disabled}
          value={comment}
          placeholder={messages.askClientCommentPlaceholder}
          onChange={handleCommentInputChange} />
        <div className={Css.main}>
          <span className={Css.icon}>
            <Icons.Question weight="bold" />
          </span>
          <span className={Css.text}>
            {Utils.replaceTextVars(
              needReactionStatusFilter ? messages.transactionsToClarifyAgain : `${messages.transactionsToClarify}?`,
              { transactionsCount }
            )}
          </span>
          <div className={classNames(Css.actions, suggestedBusinessUserName && Css.withDropdown)}>
            <Button
              warning
              disabled={disabled}
              className={Css.button}
              icon={Icons.Envelope}
              onClick={(!suggestedBusinessUserName || needReactionStatusFilter)
                ? handleAskNewClientButtonClick : handleAskClientButtonClick}>
              <span>
                {suggestedBusinessUserName
                  ? (
                    needReactionStatusFilter
                      ? uiTexts.askClient
                      : Utils.replaceTextVars(uiTexts.askClientName, { userName: suggestedBusinessUserName })
                  )
                  : uiTexts.askClientByEmail}
              </span>
            </Button>
            {!!suggestedBusinessUserName && (
              <DropDown>
                <DropDownToggle>
                  <Button
                    warning
                    disabled={disabled}
                    className={classNames(Css.button, Css.dropdownToggle)}>
                    <DropDownCaret className={Css.caret} />
                  </Button>
                </DropDownToggle>
                <DropDownContent
                  alignRight
                  portal
                  title={uiTexts.askClient}
                  modal={Utils.checkIsTouchDevice()}
                  className={Css.dropdownContent}>
                  <div className={Css.list}>
                    <DropDownMenuItem value={null} onClick={handleMenuItemClick}>
                      <Icons.Envelope /><span>{uiTexts.askAnotherClient}</span>
                    </DropDownMenuItem>
                    {!!businessUsersData.length && (
                      <>
                        <DropDownMenuDivider />
                        {businessUsersData.map(({ id, email, fullName = "" }) => {
                          return (
                            <DropDownMenuItem key={id} value={id} onClick={handleMenuItemClick}>
                              <Icons.Envelope />
                              <span className={Css.text}>
                                {Utils.replaceTextVars(uiTexts.askClientName, { userName: fullName || email })}
                              </span>
                              <Badge value={uiTexts.user} title={uiTexts.user} className={Css.badge} />
                            </DropDownMenuItem>
                          );
                        })}
                      </>
                    )}
                    {!!guestUsersData.length && (
                      <>
                        <DropDownMenuDivider />
                        {guestUsersData.map(({ id, email }) => {
                          return (
                            <DropDownMenuItem key={id} value={id} onClick={handleMenuItemClick}>
                              <Icons.Envelope />
                              <span className={Css.text}>
                                {Utils.replaceTextVars(uiTexts.askClientName, { userName: email })}
                              </span>
                              <Badge value={uiTexts.guest} title={uiTexts.user} className={Css.badge} />
                            </DropDownMenuItem>
                          );
                        })}
                      </>
                    )}
                  </div>
                </DropDownContent>
              </DropDown>
            )}
          </div>
        </div>
        <div className={Css.closeButton} onClick={handleCloseClick}>
          <Icons.X />
        </div>
      </Card>
    </Popup>
  );
};

export default React.memo(AskClientPopup);
